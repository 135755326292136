@media only screen and (min-width: 601px) and (max-width: 1260px) {
  #toast-container {
    min-width: 75%;
    bottom: 0%;
    top: 90%;
    right: 12.5%;
    left: 12.5%;
  }

  .toast {
    width: 100%;
    border-radius: 0;
  }
}

@media only screen and (min-width: 1261px) {
  #toast-container {
    min-width: 75%;
    bottom: 0%;
    top: 90%;
    right: 12.5%;
    left: 12.5%;
  }

  .toast {
    width: 100%;
    border-radius: 0;
  }
}
