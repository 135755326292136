.carousel.carousel-slider {
  float: right;
}

.filters {
  margin-bottom: 0;
}

@media only screen and (min-width: 993px) {
  .carousel {
    width: 66.66%
  }
}

.tabs-content.carousel .carousel-item {
  height: auto;
}

.tabs .tab a:hover,
.tabs .tab a.active,
.tabs .tab a {
  color: rgba(0, 0, 0, 0.87);
}

span.badge {
  margin-left: 0;
  float: unset;
}

.tabs .indicator {
  background-color: #42a5f5
}

.input-field > label {
  color: #455a64;
}

.switch label {
  font-size: initial;
}